// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBL_my22EyYGw86gtLpO1q-1vfm9aXPgP4",
    authDomain: "gamifiedrehab.firebaseapp.com",
    databaseURL: "https://gamifiedrehab-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gamifiedrehab",
    storageBucket: "gamifiedrehab.appspot.com",
    messagingSenderId: "196241439925",
    appId: "1:196241439925:web:65f9887960d3dc839f52fc",
    measurementId: "G-B7290TEPC2"
}